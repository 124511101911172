export default ({ app, store }) => {
  if (process.server) {
    return
  }

  const isProd = app.$config.RUNTIME_ENV === 'production'

  if (isProd) return

  const existingScripTag = document.querySelector('script#engine3d-build-tools')
  if (existingScripTag) {
  }

  /*
  const babylonScript = document.createElement('script')
  babylonScript.id = 'engine3d-build-tools'
  babylonScript.type = 'text/javascript'
  babylonScript.src = `//preview.babylonjs.com/babylon.js`

  document.head.appendChild(babylonScript)
  */
  /*
  const inspectorScript = document.createElement('script')
  inspectorScript.id = 'engine3d-inspector-tools'
  inspectorScript.type = 'text/javascript'
  inspectorScript.src = `//preview.babylonjs.com/inspector/babylon.inspector.bundle.js`

  document.head.appendChild(inspectorScript)
  */
}
