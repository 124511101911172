// @ts-ignore / TODO - rethink the eslint rule
export default ({ app }, inject) => {
  inject('tdb', (value: string | Record<string, string>) => {
    if (!value) return
    try {
      if (typeof value === 'string') {
        return value
      }
      if (typeof value === 'object') {
        const { locale, fallbackLocale } = app.i18n
        return value[locale] || value[fallbackLocale] || value.en || '[X]'
      }
    } catch (e) {
      console.error('debug error', e)
    }
    return '[X]'
  })
}
