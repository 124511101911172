// Actions
const CHANGE_LOADING_STATE = 'changeLoadingState'
const CHANGE_PROJECT_LOADING_STATE = 'changeProjectLoadingState'
const CHANGE_ACTIVE_PROJECT = 'changeActiveProject'
const LOAD_LANDLORD_DETAILS = 'loadLandlordDetails'
const CHANGE_CURRENT_SPACE = 'changeCurrentSpace'
const UPDATE_SURFACE_MULTIPLIER = 'updateSurfaceMultiplier'
const UPDATE_SURFACE_NAME = 'updateSurfaceName'
const LOAD_SPACE_DATA = 'loadSpaceData'
const RESET_SURFACE_FIELDS = 'resetSurfaceFields'

// Mutations
const SET_LANDLORD_DETAILS = 'setLandlordDetails'
const SET_LOADING_STATE = 'setLoadingState'
const SET_PROJECT_LOADING_STATE = 'setProjectLoadingState'
const SET_ACTIVE_PROJECT = 'setActiveProject'
const SET_CURRENT_SPACE = 'setCurrentState'
const SET_SURFACE_MULTIPLIER = 'setSurfaceMultiplier'
const SET_SURFACE_NAME = 'setSurfaceName'
const SET_SPACE_DATA = 'setSpaceData'
const SET_COMBINED_SPACE_DATA = 'setCombinedSpaceData'
const SET_DEFAULT_SURFACE_FIELDS = 'setDefaultSurfaceFields'

// Namespace
const NAMESPACE = 'project'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    CHANGE_LOADING_STATE,
    CHANGE_PROJECT_LOADING_STATE,
    CHANGE_ACTIVE_PROJECT,
    LOAD_LANDLORD_DETAILS,
    CHANGE_CURRENT_SPACE,
    UPDATE_SURFACE_MULTIPLIER,
    UPDATE_SURFACE_NAME,
    LOAD_SPACE_DATA,
    RESET_SURFACE_FIELDS
  },
  mutation: {
    SET_LANDLORD_DETAILS,
    SET_LOADING_STATE,
    SET_ACTIVE_PROJECT,
    SET_PROJECT_LOADING_STATE,
    SET_CURRENT_SPACE,
    SET_SURFACE_MULTIPLIER,
    SET_SURFACE_NAME,
    SET_SPACE_DATA,
    SET_COMBINED_SPACE_DATA,
    SET_DEFAULT_SURFACE_FIELDS
  },
  namespace: NAMESPACE,
  withNamespace
}
