import { ActionContext } from 'vuex'
import { Utils } from '@bright-spaces/engine-3d/dist/helpers/Utils'
import constants from '../-constants'
import { GuidedTourLocation, LocationType } from '~/store/guidedTour/-types'
import { activeTransition } from '~/store/guidedTour/actions/common'

const { MUTATE_CURRENT_LOCATION, MUTATE_ON_SHOW_PRESENTATION, MUTATE_ON_CLEAR_PRESENTATION } =
  constants.mutation

export default {
  checkIfLocationIsPresentation(
    { commit, dispatch }: ActionContext<any, any>,
    location: GuidedTourLocation
  ) {
    if (location?.type === LocationType.PRESENTATION) {
      dispatch('pauseTour')
      commit(MUTATE_ON_SHOW_PRESENTATION, location)
    }
    if (location?.type === LocationType.GATEWAY) {
      commit(MUTATE_ON_CLEAR_PRESENTATION)
    }
  },
  async handleLocationFade(
    { commit, dispatch }: ActionContext<any, any>,
    location: GuidedTourLocation
  ): Promise<void> {
    dispatch('checkIfLocationIsPresentation', location)
    commit(MUTATE_CURRENT_LOCATION, location.uuid)
    await dispatch('handleFadeTo', location)
  },
  handleFastTravel(
    { state, commit, getters }: ActionContext<any, any>,
    location: GuidedTourLocation
  ): Promise<void> {
    const { engine3d } = getters
    return new Promise((resolve, reject) => {
      if (engine3d) {
        let stepDurationGuidedTour =
          engine3d.enabledFeatures.fastTravelManager?.stepDurationGuidedTour
        if (typeof location.travel_time_ms !== 'undefined' && location.travel_time_ms > 0) {
          stepDurationGuidedTour = location.travel_time_ms / 1000
        }
        const transition = engine3d.enabledFeatures.fastTravelManager?.transitionToPoint(
          {
            x: location.camera_position_x,
            y: location.camera_position_y,
            z: location.camera_position_z
          },
          {
            x: location.focus_target_x,
            y: location.focus_target_y,
            z: location.focus_target_z
          },
          stepDurationGuidedTour
        )
        activeTransition.transition = transition

        // Use .then() to resolve the promise when the transition is complete
        transition
          .play()
          .then(() => {
            activeTransition.transition = null
            resolve()
          })
          .catch((error: any) => {
            console.error('Transition error', error)
            reject(error)
          })
      } else {
        resolve()
      }
    })
  },
  handleFadeTo(
    { state, commit, getters }: ActionContext<any, any>,
    location: GuidedTourLocation
  ): Promise<void> {
    const { engine3d } = getters
    return new Promise((resolve, reject) => {
      if (engine3d) {
        engine3d.transitionCamera(
          {
            x: location.camera_position_x,
            y: location.camera_position_y,
            z: location.camera_position_z
          },
          {
            x: location.focus_target_x,
            y: location.focus_target_y,
            z: location.focus_target_z
          },
          () => {
            Utils.focusCanvas()
            resolve()
          },
          false,
          true
        )
      }
    })
  },
  checkCameraTarget({ getters }: ActionContext<any, any>, location: GuidedTourLocation) {
    const { engine3d } = getters
    let samePosition = false
    if (engine3d) {
      const distance = 10
      const target = [location.focus_target_x, location.focus_target_y, location.focus_target_z]
      samePosition = engine3d.isCameraCloseToTarget(target, distance)
    }

    return samePosition
  },
  checkIfAtLastLocation({ state }: ActionContext<any, any>) {
    if (!state.currentLocation) {
      return false
    }
    const lastLocation = [...state.locations].pop()
    return lastLocation?.uuid === state.currentLocation
  },
  switchFitout(
    { state, commit, getters }: ActionContext<any, any>,
    fitoutId: string
  ): Promise<void> {
    const { engine3d } = getters
    return new Promise((resolve, reject) => {
      if (engine3d) {
        engine3d.changeFitout({id: fitoutId})
      }
    })
  }
}
