import { ProjectState } from './-types'
import constants from './-constants'

const {
  SET_LANDLORD_DETAILS,
  SET_LOADING_STATE,
  SET_PROJECT_LOADING_STATE,
  SET_ACTIVE_PROJECT,
  SET_CURRENT_SPACE,
  SET_SURFACE_MULTIPLIER,
  SET_SURFACE_NAME,
  SET_SPACE_DATA,
  SET_COMBINED_SPACE_DATA,
  SET_DEFAULT_SURFACE_FIELDS
} = constants.mutation

export default {
  [SET_LANDLORD_DETAILS](state: ProjectState, data: any) {
    state.projects = data.projects || []
    state.buildings = data.buildings || []
    const currentProject = state.projects.find((p: any) => p.slug === state.activeProject);
    state.project.buildings = data.buildings.filter((b: any) => b.project_id === currentProject.id) || []
    state.floors = data.floors || []
    state.project.floors = data.floors.filter((f: any) => ([...state.project.buildings].map(b => b.id)).includes(f.building_id)) || []
  },
  [SET_LOADING_STATE](state: ProjectState, payload: boolean) {
    state.isLoading = payload
  },
  [SET_PROJECT_LOADING_STATE](state: ProjectState, payload: boolean) {
    state.project.isLoading = payload
  },
  [SET_ACTIVE_PROJECT](state: ProjectState, payload: string) {
    state.activeProject = payload
  },
  [SET_CURRENT_SPACE](state: ProjectState, payload: string) {
    state.project.currentSpaceId = payload
  },
  [SET_SURFACE_MULTIPLIER](state: ProjectState, payload: any) {
    state.surfaceMultiplier = payload
  },
  [SET_SURFACE_NAME](state: ProjectState, payload: any) {
    state.surfaceName = payload
  },
  [SET_DEFAULT_SURFACE_FIELDS](state: ProjectState, payload: any) {
    state.surfaceName = 'GLA'
    state.surfaceMultiplier = 1
  },
  [SET_SPACE_DATA](state: ProjectState, payload: any) {
    state.spaceData = payload
  },
  [SET_COMBINED_SPACE_DATA](state: ProjectState, payload: any) {
    state.combinedSpaceData = payload
  }
}
