var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-template page-wrapper"},[_c('OrganismPageLoader'),_vm._v(" "),(_vm.showProjectSelect)?_c('MoleculesMoleculeAvailableSpacesTriggerOnMobile',{attrs:{"sections":_vm.sections}}):_vm._e(),_vm._v(" "),_c('OrganismsOrganismSidebar',[_c('div',{staticClass:"menu"},[(_vm.projects)?_c('div',[_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('portfolio')))]),_vm._v(" "),_vm._l((_vm.projects),function(project,i){return _c('NuxtLink',{key:i,staticClass:"sidebar-link",attrs:{"to":{ path: `/project/${project.slug}` }}},[_vm._v("\n          "+_vm._s(project.name)+"\n        ")])}),_vm._v(" "),_c('div',{staticClass:"separator"})],2):_vm._e(),_vm._v(" "),_c('NuxtLink',{staticClass:"sidebar-link",attrs:{"active-class":"active","tag":"a","to":{ path: '/' }}},[_vm._v(_vm._s(_vm.$t('home'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"hidden-lg"},_vm._l((_vm.menuSectionsTags),function(section,i){return _c('NuxtLink',{key:i,staticClass:"sidebar-link",attrs:{"active-class":"active","tag":"a","to":{
            path: _vm.linkPrefix,
            hash: `#${section.hashAnchor}`
          }},nativeOn:{"click":function($event){return _vm.hashChecker(`#${section.hashAnchor}`)}}},[_vm._v("\n          "+_vm._s(_vm.$tdb(section.menuLabel))+"\n        ")])}),1),_vm._v(" "),_vm._l((_vm.staticPages),function(page,i){return (page.menuLabel)?_c('NuxtLink',{key:i,staticClass:"sidebar-link",attrs:{"active-class":"active","tag":"a","to":{ path: page.path }}},[_vm._v("\n        "+_vm._s(page.menuLabel || page.name)+"\n      ")]):_vm._e()}),_vm._v(" "),_vm._l((_vm.projectSpecificStaticPages),function(page,i){return _c('NuxtLink',{key:i,staticClass:"sidebar-link",attrs:{"active-class":"active","tag":"a","to":{
          path: `/project/${_vm.activeProject}${page.path}`
        }}},[_vm._v(_vm._s(page.menuLabel || page.name)+"\n      ")])})],2),_vm._v(" "),_c('div',{staticClass:"social-links social-links-icons"},[(_vm.instagramUrl)?_c('a',{attrs:{"href":_vm.instagramUrl}},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"instagram","size":24,"color":"#000"}})],1):_vm._e(),_vm._v(" "),(_vm.linkedinUrl)?_c('a',{attrs:{"href":_vm.linkedinUrl}},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"linkedin-squared","size":24,"color":"#000"}})],1):_vm._e(),_vm._v(" "),(_vm.facebookUrl)?_c('a',{attrs:{"href":_vm.facebookUrl}},[_c('AtomsCommonAtomIcon',{attrs:{"icon":"facebook-squared","size":24,"color":"#000"}})],1):_vm._e()])]),_vm._v(" "),_c('OrganismsOrganismMenuBarProject',[_c('div',{staticClass:"hidden-xs display-inline"},_vm._l((_vm.menuSectionsTags),function(section,i){return _c('NuxtLink',{key:i,staticClass:"sidebar-link navigation-link",attrs:{"active-class":"active","tag":"a","to":{
          path: _vm.isStaticProjectPage ? `/project/${_vm.activeProject}${_vm.projectStaticPage.path}` : _vm.linkPrefix,
          hash: `#${section.hashAnchor}`
        }},nativeOn:{"click":function($event){return _vm.hashChecker(`#${section.hashAnchor}`)}}},[_vm._v("\n        "+_vm._s(_vm.$tdb(section.menuLabel))+"\n      ")])}),1)]),_vm._v(" "),_c('span',{staticClass:"back-to-top",class:{ active: _vm.isScrolled, 'lang-on': _vm.i18nActive },on:{"click":_vm.backToTop}},[_vm._v("\n    "+_vm._s(_vm.$t('tapHere'))+"\n  ")]),_vm._v(" "),_c('Nuxt'),_vm._v(" "),_c('LazyOrganismsOrganismModals')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }