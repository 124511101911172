export interface Project {
  id: number
  name: string
  slug: string
  landlord_id: number
}

export interface ProjectBuilding {
  id: number
  name: string
  code: string
  sf_name: any
  order: number
  project_id: number
  createdAt: string
  updatedAt: string
  deletedAt: any
  project: Project
  hasAvailableSpaces: boolean
  availableSpaces: number
}

export interface Building {
  id: number
  name: string
  code: string
  project: Project
}

export interface BuildingFloor {
  id: number
  name: string
  order_no: number
  no: number
  code: string
  surface: string
  nla: any
  lfa: any
  building_id: number
  floor_type_id: number
  floor_plan_url: string
  createdAt: string
  updatedAt: string
  deletedAt: any
  building: Building
  hasAvailableSpaces: boolean
  availableSpaces: number
  totalSpaces: number
}

export interface Floor {
  id: number
  order_no: number
  no: number
  name: string
  building: Building
}

export interface Space {
  id: number
  code: string
  space_code: string
  sqm: number
  sqm_range: string
  nla: any
  lfa: any
  is_public: boolean
  visibility_status: string
  type: boolean
  has_3d_model: boolean
  is_dual_level: any
  available: boolean
  lease_start: any
  lease_end: any
  available_from: any
  camera_position_x: number
  camera_position_y: number
  camera_position_z: number
  focus_target_x: number
  focus_target_y: number
  focus_target_z: number
  floor_id: number
  building_id: number
  project_id: number
  can_combine_with_id: any
  external_id: any
  matterport_url: any
  createdAt: string
  updatedAt: string
  deletedAt: any
  floor: Floor
}

export enum SurfaceRangeOptions {
  GLA = 'sqm',
  NLA = 'nla',
  LFA = 'lfa',
}

export type SurfaceRangeType = { [key in SurfaceRangeOptions]?:  { min: number, max: number } }

export type AvailabilityFilters = {
  projectId?: number
  buildingId?: number
  floorId?: number
  floorNo?: number
  minSurface?: number
  maxSurface?: number
  perPage?: number
  page?: number,
  surfaceRange: SurfaceRangeType;
  surface_field: SurfaceRangeOptions, // sqm = standard naming for gla
  filters?: any,
  clearedFilters?: boolean,
}

export type AvailabilityState = {
  isLoadingData: boolean
  isLoadingStatisticsData: boolean
  includeLeasedSpaces?: boolean
  project: ProjectBuilding[]
  building: BuildingFloor[]
  floor: Space[]
  floorMeta: any
  space: Space[]
  spaceMeta: any,
  spaceStatisticsData: {
    totalSpaces: number,
    availableSpaces: number,
    unavailableSpaces: number
  },
  filters: AvailabilityFilters,
}
