import { BaseState } from './-types'

export default (): BaseState => ({
  language: 'en',
  sidebarOpen: false,
  infoOpen: false,
  isFilterOpen: false,
  isSidebarMobileOpen: false,
  isAvailabilityMenuOpen: true,
  isChooseViewBuildingsOpen: false,
  isCollapsedMenu: true,
  meta: {},
  pageLoading: true,
  documentIsReady: false,
  landlordDetails: {},
  engine3dPluginLoading: false,
  includeLeasedSpacesInResultsList: false,
  interactiveMouseEnabled: false,
})
