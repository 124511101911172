import {ProjectState} from './-types'

export default (): ProjectState => ({
  isLoading: true,
  activeProject: '',
  projects: [],
  buildings: [],
  floors: [],
  project: {
    isLoading: true,
    currentSpaceId: '',
    buildings: [],
    floors: []
  },
  surfaceMultiplier: 1,
  surfaceName: 'GLA',
  spaceData: null,
  combinedSpaceData: null
})
