import { ActionContext } from 'vuex'
import constants from './-constants'
import { WebRequest } from '~/helpers/api'
import baseConstants from '~/store/base/-constants'
import availabilityConstants from '~/store/availability/-constants'

const {
  LOAD_LANDLORD_DETAILS,
  CHANGE_LOADING_STATE,
  CHANGE_ACTIVE_PROJECT,
  CHANGE_CURRENT_SPACE,
  UPDATE_SURFACE_MULTIPLIER,
  UPDATE_SURFACE_NAME,
  LOAD_SPACE_DATA,
  RESET_SURFACE_FIELDS
} = constants.action
const {
  SET_LANDLORD_DETAILS,
  SET_LOADING_STATE,
  SET_ACTIVE_PROJECT,
  SET_CURRENT_SPACE,
  SET_SURFACE_MULTIPLIER,
  SET_SURFACE_NAME,
  SET_SPACE_DATA,
  SET_COMBINED_SPACE_DATA,
  SET_PROJECT_LOADING_STATE,
  SET_DEFAULT_SURFACE_FIELDS
} = constants.mutation

export default {
  [LOAD_LANDLORD_DETAILS]({ commit, dispatch, rootGetters, rootState }: ActionContext<any, any>) {
    const baseState = rootState.base
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(
          baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE),
          value,
          { root: true }
        )
      }
    }
    commit(SET_LOADING_STATE, true)
    changePageLoading(true)
    return new Promise((resolve, reject) => {
      // @ts-ignore / TODO - rethink the eslint rule
      WebRequest.GET(this.$webApi.landlord.details())
        .then((res: any) => {
          commit(SET_LANDLORD_DETAILS, res.data.result)
          commit(SET_LOADING_STATE, false)
          resolve({
            result: res,
            error: null
          })
          if (res.data.result.projects.length) {
            const defaultProject = { ...res.data.result.projects[0] }
            const router = (this as any).$router
            const currentRoute = router?.app?.$route || { meta: {}, params: [] }
            if (
              (
                [
                  'project-project',
                  'project-project-space-space',
                  'embed-v2-project',
                  'embed-v2-project-space-space',
                  'embed-project',
                  'embed-project-space-space'
                ].includes(currentRoute.name)
                || currentRoute.meta.isProjectStaticPage
              )
              && (
                ('projectId' in currentRoute.params && currentRoute.params.projectId)
                || ('project' in currentRoute.params && currentRoute.params.project)
              )
            ) {
              const foundProject = res.data.result.projects.find(
                (item: any) => item.slug === currentRoute.params.projectId || item.slug === currentRoute.params.project
              )
              if (foundProject) {
                commit(SET_ACTIVE_PROJECT, foundProject.slug)
                dispatch(
                  availabilityConstants.withNamespace(
                    availabilityConstants.action.LOAD_PROJECT_AVAILABILITY_DATA
                  ),
                  { project_id: foundProject.id },
                  { root: true }
                )
              } else {
                commit(SET_ACTIVE_PROJECT, defaultProject.slug)
                dispatch(
                  availabilityConstants.withNamespace(
                    availabilityConstants.action.LOAD_PROJECT_AVAILABILITY_DATA
                  ),
                  { project_id: defaultProject.id },
                  { root: true }
                )
                  rootGetters.router.push({ name: 'NotFound', params: {} })
                }
            } else {
              commit(SET_ACTIVE_PROJECT, defaultProject.slug)
              dispatch(
                availabilityConstants.withNamespace(
                  availabilityConstants.action.LOAD_PROJECT_AVAILABILITY_DATA
                ),
                { project_id: defaultProject.id },
                { root: true }
              )
            }
          } else {
            changePageLoading(false)
          }
        })
        .catch((e: any) => {
          commit(SET_LOADING_STATE, false)
          changePageLoading(false)
          reject(new Error(e.message))
        })
    })
  },
  // eslint-disable-next-line require-await
  [CHANGE_LOADING_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_LOADING_STATE, payload)
  },
  [CHANGE_ACTIVE_PROJECT](
    { commit, dispatch, state, getters }: ActionContext<any, any>,
    payload: string
  ) {
    commit(SET_ACTIVE_PROJECT, payload)
    const activeProject = getters.getActiveProjectData
    dispatch(
      availabilityConstants.withNamespace(
        availabilityConstants.action.LOAD_PROJECT_AVAILABILITY_DATA
      ),
      { project_id: activeProject.id },
      { root: true }
    )
  },
  [CHANGE_CURRENT_SPACE]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_CURRENT_SPACE, payload)
  },
  [UPDATE_SURFACE_MULTIPLIER]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_SURFACE_MULTIPLIER, payload)
  },
  [UPDATE_SURFACE_NAME]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_SURFACE_NAME, payload)
  },
  [RESET_SURFACE_FIELDS]({ commit }: ActionContext<any, any>) {
    commit(SET_DEFAULT_SURFACE_FIELDS)
  },
  [LOAD_SPACE_DATA](
    { commit }: ActionContext<any, any>,
    payload: { spaceId: number; commitName: string }
  ) {
    commit(SET_PROJECT_LOADING_STATE, true)
    return new Promise((resolve, reject) => {
      // @ts-ignore
      WebRequest.GET(this.$webApi.space.details(payload.spaceId))
        .then((res: any) => {
          commit(payload.commitName, res.data.result)
          commit(SET_PROJECT_LOADING_STATE, false)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(payload.commitName, null)
          commit(SET_PROJECT_LOADING_STATE, false)
          reject(new Error(e.message))
        })
    })
  }
}
